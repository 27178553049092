import React from 'react';
import LaunchExperiment from '@/components/experiment/experiment';
import { ExperimentsHeader } from './experimentsHeader';
import ExperimentsOverview from './experimentsOverview';
import { useExperimentsQueryParams } from './useExperimentsQueryParams';
import { ExperimentResults } from './DMS/ExperimentResults';

function AllExperimentsComponent() {
    const [{ currentView }] = useExperimentsQueryParams();
    return (
        <div>
            <ExperimentsHeader />
            {currentView === 'overview' && <ExperimentsOverview />}
            {currentView === 'launch' && <LaunchExperiment />}
            {currentView === 'results' && <ExperimentResults />}
        </div>
    );
}

export default AllExperimentsComponent;

import { useMutation } from '@tanstack/react-query';
import { SPACEPORT_API_URL } from 'buildConfig/processEnv';
import { notification } from '@/utils/notification';
import { ExclamationMark } from 'tabler-icons-react';
import { Check } from 'phosphor-react';
import colors from '@/constants/colors';
import { useAppContext } from '../../../contexts/appContext';

type ExperimentData = {
    data: {
        experiment_name: string;
        experiment_type: 'DMS' | 'AB';
        use_case: string;
        namespace: string;
        model_ids: string[];
        random_allocations?: number[];
    };
    manual_allocation_files?: File[];
};

const useCreateExperiment = () => {
    const { user } = useAppContext();
    const postRequest = async ({ data, manual_allocation_files }: ExperimentData) => {
        const dataForUpload = new FormData();
        const cleanedData = Object.entries(data).reduce((acc, [k, v]) => {
            if (v.length > 0) {
                dataForUpload.append(k, JSON.stringify(v));
                return { ...acc, [k]: v };
            }
            return acc;
        }, {} as ExperimentData);
        const body = new FormData();
        if (manual_allocation_files?.length > 0)
            manual_allocation_files.forEach(
                file => file && body.append(`manual_allocation_files`, file),
            );
        body.append('data', JSON.stringify(cleanedData));

        const url = `${SPACEPORT_API_URL}/spaceport/api/experiments/create_experiment`;

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: {
                authorization: `Bearer ${user.siberiaToken}`,
            },
            body,
        };
        const response = await fetch(url, requestOptions);

        if (!response.ok) {
            const res = await response
                .clone()
                .text()
                .catch(() => {
                    throw new Error('Failed to parse response');
                });
            const res2 = await response
                .clone()
                .json()
                .catch(() => {
                    throw new Error('Failed to parse response');
                });
            return Promise.reject(res2?.detail ?? (res?.length > 0 ? res : 'launch failed'));
        }
        const res2 = await response
            .clone()
            .json()
            .catch(() => {
                throw new Error('Failed to parse response');
            });
        return res2;
    };

    return useMutation({
        mutationKey: ['useCreateExperiment'],
        mutationFn: postRequest,
        onError: e => {
            notification({
                message: e as unknown as string,
                icon: <ExclamationMark />,
                color: colors.red,
            });
        },
        onSuccess: e => {
            notification({
                message: 'Your file has been uploaded.',
                color: colors.darkGreen,
                icon: <Check />,
            });
        },
    });
};

export default useCreateExperiment;

import { SPACEPORT_API_URL } from '../../../buildConfig/processEnv';
import useApiQuery from '../../../hooks/useApiQuery';

export interface UseCaseApiResponse {
    items: UseCaseItem[];
    count: number;
}

export interface UseCaseItem {
    id: string;
    name: string;
    description: string;
}

export const useActiveUseCaseList = (namespace: string, disabled?: boolean) =>
    useApiQuery<UseCaseApiResponse>({
        url: `${SPACEPORT_API_URL}/spaceport/api/console/${namespace}/active_use_cases`,
        queryKeys: [namespace],
        disabled,
    });

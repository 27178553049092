import AllExperimentsComponent from '@/components/console/experiments/allExperimentsComponent';
import { EXPERIMENT_USER_GROUP_WHITELIST } from '@/components/navBar/constants';
import ResponsiveLayout from '@/components/responsive/ResponsiveLayout';
import { UserGuard } from '@/components/utils/UserGuard';
import React from 'react';

function AllExperiments() {
    return (
        <ResponsiveLayout title="Experiments">
            <UserGuard userGroupWhitelist={EXPERIMENT_USER_GROUP_WHITELIST}>
                <AllExperimentsComponent />
            </UserGuard>
        </ResponsiveLayout>
    );
}

export default AllExperiments;

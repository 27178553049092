import {
    Info,
    MagnifyingGlass,
    Package,
    Percent,
    RocketLaunch,
    Sparkle,
    Upload,
} from 'phosphor-react';
import {
    Badge,
    FileInput,
    Input,
    Modal,
    NumberInput,
    Skeleton,
    Slider,
    Table,
} from '@mantine/core';
import { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import {
    COUNTRIES_MAPPED_TO_COUNTRY_LIST,
    COUNTRY_CODE_NAME_MAP,
} from '@/types/hooks/useTableQuery';
import MultiSelect from 'andromeda/selects/multiSelect';
import Select from 'andromeda/selects/select';
import { SelectItem } from 'andromeda/types/select/common';
import Text from 'andromeda/text';
import { countries, getEmojiFlag, TCountryCode } from 'countries-list';
import { formatNumber } from '@/utils/numberFormatHelpers';
import { Button } from 'andromeda/Button';
import { grayScale } from '@/constants/colors';
import { BareCard } from 'andromeda';
import StyledTable from 'andromeda/styledTable';
import { DropdownOption } from '../../andromeda/Dropdown';
import { formatUseCaseName } from '../utils/UseCaseDropdown';
import { useNamespaceList } from '../../hooks/useNamespaceList';
import { useUserAccess } from '../../hooks/useUserAccess';
import { useUserAllowedNamespaces } from '../../hooks/useUserAllowedNamespaces';
import { useActiveUseCaseList } from './hooks/useActiveUseCaseList';
import { DMSModal } from './DMSModal';
import useModelIdsRegisteredQuery from './hooks/useModelIdsRegistered';
import useCreateExperiment from './hooks/useCreateExperiment';
import { SORT_ORDER, USE_CASE_ICONS } from './constants/experiments';
import { CONSOLE_MODULE_COLOR_MAP } from '../console/ConsoleModule';

const SelectableCards = ({
    icon,
    title,
    isSelected,
    className,
    onClick,
}: {
    icon: JSX.Element;
    title: string | JSX.Element;
    isSelected: boolean;
    className?: string;
    onClick: () => void;
}) => (
    <div
        className={classNames(
            'gradient-bg rounded-xl !p-2 border-l border-t border-border-color',
            className,
        )}
    >
        <button
            type="button"
            className={classNames('flex h-full justify-start items-center gap-2', {
                'text-navy-solid-70': isSelected,
                'text-navy-solid-50 hover:text-navy-solid-70 hover:opacity-100 opacity-85':
                    !isSelected,
            })}
            onClick={onClick}
        >
            {icon}
            {typeof title === 'string' ? <span className="text-sm">{title}</span> : title}
        </button>
    </div>
);

const LaunchExperiment = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSuccessScreen, setIsSuccessScreen] = useState(false);
    const [error, setError] = useState<string>();
    const [selectedMethod, setSelectedMethod] = useState<'Allocation Percentage' | 'POC Upload'>(
        'Allocation Percentage',
    );
    const [allocationPercentage, setAllocationPercentage] = useState([]);
    const [allocationFiles, setAllocationFiles] = useState<File[]>([]);
    const allocationPercentageTotal = allocationPercentage.reduce((n, m) => n + m, 0);
    const allocationPercentageError =
        allocationPercentageTotal > 1 || allocationPercentageTotal < 1;

    const [showWarnings, setShowWarnings] = useState(false);
    const [conformingModal, setConformingModal] = useState(false);

    const [selectedNamespace, setSelectedNamespace] = useState<string>('PH-NESTLE');
    const [selectedUseCase, setSelectedUseCase] = useState<string>();
    const [experimentType, setExperimentType] = useState<'DMS' | 'AB'>('AB');
    const [experimentTitle, setExperimentTitle] = useState<string>('');
    const [selectedModels, setSelectedModels] = useState<string[]>([]);

    const { data: moduleData, isLoading } = useActiveUseCaseList(selectedNamespace);
    const { data: namespaceData } = useNamespaceList();
    const { data: models } = useModelIdsRegisteredQuery(selectedNamespace, selectedUseCase);
    const createExperimentMtx = useCreateExperiment();

    const namespacesUserAllowed = useUserAllowedNamespaces();
    const isArenaStaff = useUserAccess({ userGroupWhitelist: 'arena-staff' });

    const namespaceOptions: SelectItem[] = useMemo(
        () =>
            namespaceData
                ?.filter(
                    namespace => namespacesUserAllowed.includes(namespace.name) || isArenaStaff,
                )
                ?.sort((a, b) => a.name.localeCompare(b.name))
                ?.reduce((acc: SelectItem[], namespace) => {
                    const [country, ...rest] = namespace.name.split('-');

                    let label =
                        COUNTRY_CODE_NAME_MAP?.[country] ??
                        countries?.[COUNTRIES_MAPPED_TO_COUNTRY_LIST?.[country] ?? country]?.name ??
                        country;
                    if (namespace.name.includes('-')) {
                        label += `-${rest.join(' ')}`;
                    }

                    return !namespace.name
                        ? acc
                        : [
                              ...acc,
                              {
                                  value: namespace.name,
                                  label,
                                  icon: `${getEmojiFlag(COUNTRIES_MAPPED_TO_COUNTRY_LIST?.[country] ?? (country as TCountryCode))}   `,
                              },
                          ];
                }, []) ?? [],
        [isArenaStaff, namespaceData, namespacesUserAllowed],
    );
    const useCaseOptions: DropdownOption[] = useMemo(
        () =>
            // ? Hack until the BE is ready with the use_cases
            // eslint-disable-next-line no-constant-condition
            (false
                ? // (moduleData?.['use_cases'].length > 0
                  moduleData?.['use_cases']
                : ['QUICK_ORDER', 'CROSS_SELL_UP_SELL', 'FORGOTTEN_ITEMS', 'DEAL_SORT']
            )
                ?.flatMap(useCase => {
                    if (useCase === 'DEAL_SORT') return [];
                    return {
                        value: useCase,
                        label: formatUseCaseName(useCase) ?? useCase,
                    };
                })
                .sort((a, b) => SORT_ORDER[b.value] - SORT_ORDER[a.value]),
        [moduleData],
    );

    const modelOptions: DropdownOption[] = useMemo(
        () =>
            models?.map(model => ({
                value: model.model_id,
                label: model.model_id,
            })),
        [models],
    );

    const createExperiment = useCallback(async () => {
        const r = await createExperimentMtx.mutateAsync({
            data: {
                namespace: selectedNamespace,
                use_case: selectedUseCase,
                experiment_name: experimentTitle,
                experiment_type: experimentType,
                model_ids: selectedModels,
                random_allocations: allocationPercentage,
            },
            manual_allocation_files: allocationFiles,
        });

        if (Object.values(r ?? {}).length > 0) {
            setError(Object.values(r ?? {})[0] as string);
            return;
        }
        setIsSuccessScreen(true);
    }, [
        allocationFiles,
        allocationPercentage,
        createExperimentMtx,
        experimentTitle,
        experimentType,
        selectedModels,
        selectedNamespace,
        selectedUseCase,
    ]);

    const clearAllocationsState = useCallback(() => {
        setAllocationFiles([]);
        setAllocationPercentage(selectedModels.map(() => 1 / selectedModels.length));
    }, [selectedModels]);

    const updateNamespace = useCallback(
        (namespace: string) => {
            setSelectedNamespace(namespace);
            // reset other states
            setSelectedUseCase('');
            setSelectedModels([]);
            clearAllocationsState();
        },
        [clearAllocationsState],
    );

    const updateUseCase = useCallback(
        (useCase: string) => {
            setSelectedUseCase(useCase);
            // reset other states
            clearAllocationsState();

            setSelectedModels([]);
        },
        [clearAllocationsState],
    );

    const selectedNamespaceItem = useMemo(
        () => namespaceOptions.filter(item => item.value === selectedNamespace)?.[0],
        [namespaceOptions, selectedNamespace],
    );

    const shouldPreventLaunch = useMemo(
        () =>
            !selectedNamespace ||
            !selectedUseCase ||
            !experimentTitle ||
            selectedModels.length === 0 ||
            (experimentType === 'AB'
                ? selectedMethod === 'POC Upload'
                    ? selectedModels.length !== allocationFiles.filter(x => !!x).length
                    : allocationPercentageError
                : selectedModels.length === 0),
        [
            allocationFiles,
            allocationPercentageError,
            experimentTitle,
            experimentType,
            selectedMethod,
            selectedModels.length,
            selectedNamespace,
            selectedUseCase,
        ],
    );

    return (
        <>
            <div className="flex w-full grow flex-col px-11">
                {isSuccessScreen ? (
                    <div className="flex flex-col gap-9 pt-32">
                        <div className="text-3xl font-medium text-primary">
                            Congratulations, you just launched a new experiment!
                        </div>
                        <p>Experiment: {experimentTitle}</p>
                        <p>Region: {selectedNamespace}</p>
                        <p>Use Case: {selectedUseCase}</p>
                        <Button
                            className="mt-10"
                            leftSection={<RocketLaunch size={20} />}
                            onClick={() => setIsSuccessScreen(false)}
                            loading={false}
                        >
                            Launch another
                        </Button>
                    </div>
                ) : (
                    <div className="flex flex-col gap-12 pt-4">
                        <div className="flex flex-col gap-10">
                            <div className="flex flex-col gap-4">
                                <div className="flex items-center gap-3">
                                    <div className="text-sm font-medium text-navy">
                                        Step 1: Name your experiment
                                    </div>
                                </div>
                                <div className="w-[500px]">
                                    <Input
                                        value={experimentTitle}
                                        onChange={e => setExperimentTitle(e.target.value)}
                                        classNames={{ input: 'text-xs !border-none' }}
                                        placeholder="Enter a name for your experiment"
                                        className="gradient-bg-hex w-full rounded border border-border-color focus:border-primary-50"
                                    />
                                    {showWarnings && experimentTitle.trim().length === 0 && (
                                        <div className="text-xs text-red-400">
                                            Input a title for your experiment.
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="flex flex-col gap-4">
                                <div className="flex items-center gap-3">
                                    <div className="flex items-center gap-8">
                                        <div className="text-sm font-medium text-navy">
                                            Step 2: Select an experiment type
                                        </div>
                                        <button
                                            type="button"
                                            className="group flex cursor-pointer items-center gap-2"
                                            onClick={() => setIsModalOpen(true)}
                                        >
                                            <Info className="text-risd-blue" size={20} />
                                            <p className="text-sm font-medium text-navy-solid-30 group-hover:text-navy-solid-70">
                                                What is Dynamic Model Selection?
                                            </p>
                                        </button>
                                    </div>
                                </div>

                                <div className="mb-1 flex items-center gap-4">
                                    <SelectableCards
                                        onClick={() => {
                                            setExperimentType('DMS');
                                            clearAllocationsState();
                                        }}
                                        icon={<Package size={26} />}
                                        className="h-[69px] w-60"
                                        title={
                                            <div className="flex flex-col">
                                                <div className="text-sm">
                                                    Dynamic Model Selection
                                                </div>
                                                <div className="text-xs">
                                                    Reinforcement Learning to apply optimal
                                                    recommendations
                                                </div>
                                            </div>
                                        }
                                        isSelected={experimentType === 'DMS'}
                                    />
                                    <SelectableCards
                                        isSelected={experimentType === 'AB'}
                                        className="h-[69px] w-fit pr-4"
                                        onClick={() => {
                                            setExperimentType('AB');
                                            clearAllocationsState();
                                        }}
                                        icon={<Sparkle size={20} />}
                                        title={
                                            <div className="flex h-full flex-col justify-start">
                                                <div className="text-sm">A/B Testing</div>
                                                <div className="pr-1 text-xs">
                                                    Traditional A/B testing
                                                </div>
                                            </div>
                                        }
                                    />
                                </div>
                            </div>

                            <div className="flex flex-col gap-4">
                                <div className="flex items-center gap-3">
                                    <div className="flex items-center gap-8">
                                        <div className="text-sm font-medium text-navy">
                                            Step 3: Where would you like to run your experiment?
                                        </div>
                                    </div>
                                </div>

                                <div className="flex flex-col">
                                    <div className="flex w-1/2 flex-row flex-wrap gap-4">
                                        <Select
                                            placeholder="Select a Region"
                                            data={namespaceOptions ?? []}
                                            onChange={updateNamespace}
                                            value={selectedNamespace}
                                            leftSection={<MagnifyingGlass size={19} />}
                                            gradientBG
                                        />
                                        {showWarnings && !selectedNamespace && (
                                            <div className="text-xs text-red-400">
                                                Select a region.
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-col gap-4">
                                <div className="flex items-center gap-3">
                                    <div className="flex items-center gap-8">
                                        <div className="text-sm font-medium text-navy">
                                            Step 4: What module would you like to launch your
                                            experiment for?
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col">
                                    <div className="flex flex-row gap-4">
                                        {isLoading ? (
                                            <>
                                                <Skeleton height={37} width={140} />
                                                <Skeleton height={37} width={130} />
                                                <Skeleton height={37} width={120} />
                                            </>
                                        ) : (
                                            useCaseOptions?.map(row => (
                                                <SelectableCards
                                                    key={row.value}
                                                    isSelected={selectedUseCase === row.value}
                                                    onClick={() => updateUseCase(row.value)}
                                                    title={row.label}
                                                    icon={USE_CASE_ICONS[row.value]}
                                                />
                                            ))
                                        )}
                                    </div>
                                    {showWarnings && !selectedUseCase && (
                                        <div className="pl-1 text-xs text-red-400">
                                            Select a use case.
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="flex flex-col gap-4">
                                <div className="flex items-center gap-3">
                                    <div className="text-sm font-medium text-navy">
                                        Step 5: What models would you like to use in your
                                        experiment?
                                    </div>
                                </div>
                                <div className="flex w-[303px] flex-col gap-4">
                                    <div className="flex-1">
                                        <MultiSelect
                                            id="tags"
                                            data={modelOptions}
                                            value={selectedModels}
                                            onChange={v => {
                                                if (experimentType === 'AB') {
                                                    if (v.length > 1)
                                                        setAllocationPercentage(
                                                            v.map(i => 1 / v.length),
                                                        );
                                                    else {
                                                        setAllocationPercentage([1]);
                                                    }
                                                }
                                                setSelectedModels(v);
                                            }}
                                            onClick={() => setShowWarnings(true)}
                                            width={300}
                                            placeholder="Search for a model"
                                            gradientBG
                                            disabled={!selectedUseCase}
                                        />
                                        {showWarnings && selectedModels.length === 0 && (
                                            <div className="text-xs text-red-400">
                                                Select model(s).
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {experimentType === 'AB' && (
                                <>
                                    <div className="flex flex-col gap-4">
                                        <div className="flex items-center gap-3">
                                            <div className="text-sm font-medium text-navy">
                                                Step 6: Select an allocation method.
                                            </div>
                                        </div>
                                        <div className="flex w-[303px] flex-col gap-4">
                                            <Select
                                                id="method"
                                                data={['Allocation Percentage', 'POC Upload']}
                                                value={selectedMethod}
                                                onChange={v => {
                                                    setSelectedMethod(v as typeof selectedMethod);
                                                    clearAllocationsState();
                                                }}
                                                width="300px"
                                                placeholder="Search for a model"
                                                gradientBG
                                                // nothingFound="No model found for the selected region and module."
                                            />
                                            {showWarnings && selectedModels.length === 0 && (
                                                <div className="text-xs text-red-400">
                                                    Select model(s).
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-4">
                                        <div className="flex items-center gap-3">
                                            <div className="text-sm font-medium text-navy">
                                                Step 7: Specify Allocations
                                            </div>
                                        </div>
                                        <div className="flex w-[303px] flex-col gap-4">
                                            <div className="flex flex-col gap-3">
                                                {(experimentType === 'AB'
                                                    ? selectedModels
                                                    : ['']
                                                ).map((model, modelIndex) =>
                                                    selectedMethod === 'Allocation Percentage' ? (
                                                        <div key={model}>
                                                            <Text
                                                                type="14Mid"
                                                                className="capitalize"
                                                            >
                                                                {model} Allocation Percentage
                                                            </Text>
                                                            <div className="flex items-center gap-2">
                                                                <Slider
                                                                    min={0}
                                                                    max={1}
                                                                    step={0.01}
                                                                    value={
                                                                        allocationPercentage[
                                                                            modelIndex
                                                                        ]
                                                                    }
                                                                    onChange={e => {
                                                                        setAllocationPercentage(
                                                                            prev =>
                                                                                prev.toSpliced(
                                                                                    modelIndex,
                                                                                    1,
                                                                                    e,
                                                                                ),
                                                                        );
                                                                    }}
                                                                    w="100%"
                                                                    classNames={{
                                                                        track: 'bg-navy-solid-30',
                                                                    }}
                                                                    label={n =>
                                                                        formatNumber(n, '0%')
                                                                    }
                                                                />
                                                                <NumberInput
                                                                    value={
                                                                        allocationPercentage[
                                                                            modelIndex
                                                                        ] * 100
                                                                    }
                                                                    w={80}
                                                                    onChange={e => {
                                                                        if (typeof e !== 'number')
                                                                            return;
                                                                        setAllocationPercentage(
                                                                            prev =>
                                                                                prev.toSpliced(
                                                                                    modelIndex,
                                                                                    1,
                                                                                    e / 100,
                                                                                ),
                                                                        );
                                                                    }}
                                                                    rightSection={<Percent />}
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className="flex flex-col gap-2"
                                                            key={model}
                                                        >
                                                            <Text
                                                                type="14Mid"
                                                                className="capitalize"
                                                            >
                                                                {model} POC Upload{' '}
                                                                <Text
                                                                    type="14Mid"
                                                                    className="!text-navy-solid-15"
                                                                >
                                                                    (CSV, XLSX)
                                                                </Text>
                                                            </Text>
                                                            <ul className="!list-disc pl-4 text-xs text-navy-solid-30">
                                                                <li>
                                                                    The uploaded file must have a
                                                                    single column for the header and
                                                                    the &apos;account_id&apos; as
                                                                    string values.
                                                                </li>
                                                            </ul>
                                                            <FileInput
                                                                fileInputProps={{
                                                                    accept: 'text/csv, xlsx',
                                                                }}
                                                                placeholder="Upload file (CSV, XLSX)"
                                                                classNames={{
                                                                    input: 'rounded-lg !bg-navy-solid-30 !text-navy-solid-15 w-full hover:!border-navy-solid-10',
                                                                }}
                                                                clearButtonProps={{
                                                                    className:
                                                                        '!text-navy-solid-15 hover:bg-shadow-100',
                                                                }}
                                                                w={200}
                                                                value={
                                                                    allocationFiles?.[modelIndex]
                                                                }
                                                                onChange={e => {
                                                                    if (!e) {
                                                                        setAllocationFiles(prev =>
                                                                            prev.toSpliced(
                                                                                modelIndex,
                                                                                1,
                                                                                null,
                                                                            ),
                                                                        );
                                                                        return;
                                                                    }
                                                                    setAllocationFiles(prev =>
                                                                        prev.toSpliced(
                                                                            modelIndex,
                                                                            1,
                                                                            e,
                                                                        ),
                                                                    );
                                                                }}
                                                                leftSection={
                                                                    <Upload
                                                                        size={24}
                                                                        className="mx-2 !text-navy-solid-15"
                                                                        weight="regular"
                                                                    />
                                                                }
                                                                clearable
                                                            />
                                                        </div>
                                                    ),
                                                )}
                                                {selectedModels.length === 0 && (
                                                    <div className="-mt-2 inline-flex items-center gap-1 text-xs text-navy-solid-15">
                                                        <Info /> Select model(s).
                                                    </div>
                                                )}
                                                {selectedMethod === 'Allocation Percentage' &&
                                                    allocationPercentage.length > 0 &&
                                                    allocationPercentageError && (
                                                        <div className="text-red-500">
                                                            Allocations sum must be equal to 100%
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <Button
                            leftSection={<RocketLaunch size={16} />}
                            onClick={() => setConformingModal(true)}
                            className="!mb-1 mt-16 !w-fit !rounded-3xl bg-risd-blue text-xs font-normal hover:bg-risd-blue hover:opacity-80 focus:focus-within:bg-risd-blue"
                            color="blue"
                            loading={false}
                            inactive={shouldPreventLaunch}
                        >
                            Launch Experiment
                        </Button>
                        {error && (
                            <p className="-mt-10 text-red-500">
                                {error?.['details'] ?? JSON.stringify(error)}
                            </p>
                        )}
                    </div>
                )}
            </div>
            <DMSModal opened={isModalOpen} onClose={() => setIsModalOpen(false)} />
            <Modal
                opened={conformingModal}
                onClose={() => setConformingModal(false)}
                title="Confirm launch experiment"
            >
                <div className="flex flex-col gap-3">
                    <Text type="12Mid" className="!text-navy-solid-50">
                        We&apos;re ready to launch, please give your experiment a final review:
                    </Text>
                    <Text type="12Reg" className="!text-navy-solid-30">
                        Experiment &quot;{experimentTitle}&quot;{' '}
                        <Badge
                            color={CONSOLE_MODULE_COLOR_MAP?.[selectedUseCase] ?? grayScale[60]}
                            className="ml-1 inline-block"
                            size="sm"
                        >
                            {formatUseCaseName(selectedUseCase)}
                        </Badge>{' '}
                        <strong className="pl-1">
                            {selectedNamespaceItem?.icon}
                            {selectedNamespaceItem?.label}
                        </strong>
                    </Text>
                    <StyledTable
                        headers={
                            <Table.Tr>
                                <Table.Th className="!pl-4 !text-left">Group</Table.Th>
                                <Table.Th className="!text-left">
                                    {experimentType === 'AB' && 'Allocation'}
                                </Table.Th>
                            </Table.Tr>
                        }
                        body={selectedModels.map((item, i) => (
                            <Table.Tr key={item}>
                                <Table.Td className="!pl-4 !text-left">{item}</Table.Td>
                                <Table.Td className="!text-left">
                                    {experimentType === 'AB' &&
                                        (allocationFiles?.[i]?.name ??
                                            formatNumber(allocationPercentage?.[i], '0%'))}
                                </Table.Td>
                            </Table.Tr>
                        ))}
                    />
                    <div className="flex items-center justify-end gap-3">
                        <Button onClick={() => setConformingModal(false)} variant="secondary">
                            Cancel
                        </Button>
                        <Button
                            onClick={() => {
                                createExperiment();
                                setConformingModal(false);
                            }}
                        >
                            Launch
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default LaunchExperiment;

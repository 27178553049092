import useApiQuery from '../../../../../hooks/useApiQuery';
import { useExperimentsQueryParams } from '../../useExperimentsQueryParams';
import { DjApiPaths } from '../ApiPaths';

type AgentManagerModelList = {
    id: string;
    name: string;
    start_date: string;
    end_date: string;
    created_by: string;
    use_case: string;
    namespace: string;
    num_models: number;
};

export const useAgentManagerModelList = () => {
    const [{ namespace, usecase }] = useExperimentsQueryParams();
    return useApiQuery<AgentManagerModelList[]>({
        url: DjApiPaths.GetAgentManagerModelList(usecase, namespace),
        disabled: !namespace || !usecase,
    });
};

export type { AgentManagerModelList };

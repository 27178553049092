import { CaretRight } from 'phosphor-react';
import React from 'react';
import { SelectItem } from 'andromeda/types/select/common';
import Text from 'andromeda/text';

const TBDBreadCrumbItem = ({ label }: { label: string }) => (
    <Text type="12Mid" className="h-full max-w-[180px] truncate capitalize !text-primaryBlue">
        {label}
    </Text>
);

const TBDBreadCrumbLinkItem = ({ label, onClick }: { label: string; onClick: () => void }) => (
    <>
        <button
            type="button"
            onClick={onClick}
            className="h-full max-w-[180px] truncate capitalize"
        >
            <Text type="12Reg" className="!text-primary-70 hover:!text-primaryBlue">
                {label}
            </Text>
        </button>
        <CaretRight
            width={8}
            height={8}
            className="min-h-[8px] min-w-[8px] !text-navy-solid-70"
            weight="bold"
        />
    </>
);

function TBDBreadcrumb({
    crumbs,
    children,
    onClick,
}: {
    crumbs: (string | SelectItem)[];
    children?: JSX.Element;
    onClick: (v: string) => void;
}) {
    return (
        <div className="mb-4 flex items-baseline gap-2">
            {crumbs.map((item, i, arr) =>
                i >= arr.length - 1 ? (
                    <TBDBreadCrumbItem
                        key={item?.['value'] ?? item}
                        label={item?.['label'] ?? item}
                    />
                ) : (
                    <TBDBreadCrumbLinkItem
                        key={item?.['value'] ?? item}
                        label={item?.['label'] ?? item}
                        onClick={() => {
                            onClick(item?.['value'] ?? item);
                        }}
                    />
                ),
            )}
            {children}
        </div>
    );
}

export default TBDBreadcrumb;
export { TBDBreadCrumbLinkItem as BreadCrumbItem, TBDBreadCrumbItem as LastBreadCrumbItem };

import { ArrowCircleUp, CircleWavyQuestion, Funnel, Lightbulb, Medal } from 'phosphor-react';

export const USE_CASE_ICONS = {
    FORGOTTEN_ITEMS: <CircleWavyQuestion size={19} />,
    CROSS_SELL_UP_SELL: <ArrowCircleUp size={19} />,
    DEAL_SORT: <Funnel size={19} />,
    QUICK_ORDER: <Lightbulb size={19} />,
    FEATURED_OFFERS: <Medal size={19} />,
};

export const SORT_ORDER = {
    QUICK_ORDER: 5,
    CROSS_SELL_UP_SELL: 4,
    FORGOTTEN_ITEMS: 3,
    FEATURED_OFFERS: 2,
    DEAL_SORT: 1,
};

import { useQuery } from '@tanstack/react-query';
import { useAppContext } from '../../../contexts/appContext';
import { SPACEPORT_API_URL } from '../../../buildConfig/processEnv';

const useModelIdsRegisteredQuery = (namespace: string, useCase: string) => {
    const { user } = useAppContext();
    const postRequest = async () => {
        try {
            const requestOptions: RequestInit = {
                headers: {
                    accept: 'application/json',
                    authorization: `Bearer ${user.siberiaToken}`,
                },
            };

            const url = `${SPACEPORT_API_URL}/spaceport/api/experiments/model_ids_registered?namespace=${namespace}&use_case=${useCase}`;

            const response = await fetch(url, requestOptions);

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const body = await response.json();
            return body;
        } catch (error) {
            throw new Error('An error occurred while making the post request');
        }
    };

    return useQuery({
        queryKey: ['useCaseLevelMetrics', useCase, namespace],
        queryFn: postRequest,
        enabled: namespace?.length > 0 && useCase?.length > 0,
    });
};

export default useModelIdsRegisteredQuery;

import TableHeaderCell, {
    AgentManagerModelListKeys,
} from '@/components/pricingIntelligence/pricingDetails/table/tableHeaderCell';
import StyledTable from 'andromeda/styledTable';
import { handleCellClickType, SortingType } from 'andromeda/tableHeaderCellCarret';
import { bannerAssortmentChartDateFormatter, fusionMoment } from '@/utils/dateHelpers';
import React, { useCallback, useMemo, useState } from 'react';
import { Table } from '@mantine/core';
import colors from '@/constants/colors';
import { CONSOLE_MODULE_NAME_MAP } from '../ConsoleModule';
import { useAgentManagerModelList } from './DMS/hooks/useAgentManagerModelList';
import { useExperimentsQueryParams } from './useExperimentsQueryParams';

const headers: Partial<Record<AgentManagerModelListKeys | 'status', string>> = {
    status: '',
    name: 'Title',
    start_date: 'date Launched',
    end_date: 'date ended',
    num_models: '# models',
    created_by: 'who launched',
};

function ExperimentsOverview() {
    const { data, isLoading } = useAgentManagerModelList();
    const [, updateQueryParam] = useExperimentsQueryParams();
    const [tableHeaderHasFocus, setTableHeaderHasFocus] = useState<Record<string, boolean>>();
    const [sorting, setSorting] = useState<{
        column: AgentManagerModelListKeys;
        type: SortingType;
    }>({
        column: 'start_date',
        type: 'desc',
    });
    const onTableHeaderClick = useCallback(
        (column: AgentManagerModelListKeys) => {
            setSorting({
                column,
                type: handleCellClickType((column === sorting.column && sorting.type) || false),
            });
        },
        [sorting.column, sorting.type],
    );

    const body = useMemo(
        () =>
            data
                ?.sort((a, b) => {
                    if (!sorting.column) {
                        // ? sorting by status when no column is selected
                        return (!a.end_date ? 'aa' : 'bb').localeCompare(!b.end_date ? 'aa' : 'bb');
                    }
                    const column = sorting.column;
                    let aX = a[column];
                    let bX = b[column];

                    if (typeof aX === 'string' && typeof bX === 'string') {
                        if (column.includes('date')) {
                            if (sorting.type === 'asc')
                                return new Date(aX).getTime() - new Date(bX).getTime();
                            return new Date(bX).getTime() - new Date(aX).getTime();
                        }
                        if (sorting.type === 'asc')
                            return (aX || '----')?.localeCompare(bX || '----');
                        return (bX || '----')?.localeCompare(aX || '----');
                    }
                    aX = a[column] ?? 0;
                    bX = b[column] ?? 0;
                    if (typeof aX === 'number' && typeof bX === 'number') {
                        if (sorting.type === 'asc') return bX - aX;
                        return aX - bX;
                    }
                    return 1 - 0;
                })
                ?.map((exp, i) => (
                    <Table.Tr
                        key={`all-exp${i * 1}`}
                        className="cursor-pointer hover:bg-shadow-200"
                        onClick={() => {
                            updateQueryParam({
                                multipleValues: {
                                    currentView: 'results',
                                    namespace: exp.namespace,
                                    usecase: exp.use_case,
                                    agentManagerModelId: exp.id,
                                    expStartDate: exp.start_date,
                                    expEndDate: exp.end_date,
                                },
                            });
                        }}
                    >
                        {Object.keys(headers).map(key => {
                            if (key === 'status') {
                                return (
                                    <Table.Td key={exp[key] + key}>
                                        <div
                                            className="size-2 rounded-full"
                                            style={{
                                                background: fusionMoment(
                                                    exp.end_date ?? '----',
                                                ).isValid()
                                                    ? colors.red
                                                    : colors.green,
                                            }}
                                        />
                                    </Table.Td>
                                );
                            }
                            let value = exp?.[key] ?? '----';
                            if (key !== 'num_models' && fusionMoment(value).isValid()) {
                                value = bannerAssortmentChartDateFormatter(value);
                            } else if (CONSOLE_MODULE_NAME_MAP?.[value]) {
                                value = CONSOLE_MODULE_NAME_MAP[value];
                            }
                            return <Table.Td key={exp[key] + key}>{value}</Table.Td>;
                        })}
                    </Table.Tr>
                )),
        [data, sorting.column, sorting.type, updateQueryParam],
    );

    return (
        <StyledTable
            body={body}
            headers={Object.entries(headers).map(([key, displayValue]) =>
                key === 'status' ? (
                    <Table.Th
                        key={key}
                        className="whitespace-pre !text-center"
                        aria-label="Status"
                    />
                ) : (
                    <Table.Th className="whitespace-pre !text-center" key={displayValue}>
                        <TableHeaderCell<AgentManagerModelListKeys>
                            cell={key as AgentManagerModelListKeys}
                            onClick={() => onTableHeaderClick(key as AgentManagerModelListKeys)}
                            sorting={sorting}
                            handleFocus={obj =>
                                setTableHeaderHasFocus(prev => ({
                                    ...prev,
                                    ...obj,
                                }))
                            }
                            focusBank={tableHeaderHasFocus}
                            className="!text-start text-navy-solid-50"
                        >
                            {displayValue}
                        </TableHeaderCell>
                    </Table.Th>
                ),
            )}
            loading={isLoading}
            dataCondition={data?.length === 0}
            noDataText="No Data Available, contact abi-ghq-support@arena-ai.com for more information."
            maxHeight="calc(100dvh - 250px)"
            spaceportBareCardClassName="!min-h-fit"
        />
    );
}

export default ExperimentsOverview;

import cx from 'classnames';
import { Button } from 'andromeda/Button';
import { Plus } from 'phosphor-react';
import TBDBreadcrumb from '@/components/tbd/breadcrumb';
import { bannerAssortmentChartDateFormatter } from '@/utils/dateHelpers';
import { Badge } from 'andromeda';
import colors from '@/constants/colors';
import { useExperimentsQueryParams } from './useExperimentsQueryParams';
import { UseCaseDropdown } from '../../utils/UseCaseDropdown';
import { SpaceportCommonHeader } from '../../utils/SpaceportCommonHeader';
import { NamespaceDropdown } from '../../utils/NamespaceDropdown';

const ActionsElement = () => {
    const [{ usecase, namespace, currentView, expEndDate, expStartDate }, updateQueryParam] =
        useExperimentsQueryParams();

    return (
        <div
            className={cx(
                'flex flex-col gap-3 items-start justify-between w-full',
                'md:items-end md:w-auto xl:items-start',
                'max-md:flex-row max-sm:flex-col max-md:items-center max-sm:items-start',
            )}
        >
            <div className="flex items-center gap-4">
                <NamespaceDropdown
                    selectedNamespace={namespace}
                    updateSelectedNamespace={value =>
                        updateQueryParam({
                            multipleValues: { namespace: value, currentView: 'overview' },
                        })
                    }
                />
                <UseCaseDropdown
                    selectedModule={usecase}
                    selectedNamespace={namespace}
                    updateSelectedModule={value =>
                        updateQueryParam({
                            multipleValues: { usecase: value, currentView: 'overview' },
                        })
                    }
                />

                <Button
                    leftSection={<Plus />}
                    variant="secondary"
                    onClick={() => updateQueryParam({ value: 'launch', key: 'currentView' })}
                    inactive={currentView === 'launch'}
                >
                    Launch new
                </Button>
            </div>
            {currentView === 'results' && (
                <Badge color={!expEndDate ? colors.darkGreen : colors.arenaBlue}>
                    {expStartDate ? bannerAssortmentChartDateFormatter(expStartDate) : ''}{' '}
                    {expEndDate
                        ? `→ ${bannerAssortmentChartDateFormatter(expEndDate)}`
                        : '(On Going)'}
                </Badge>
            )}
        </div>
    );
};

export const ExperimentsHeader = () => {
    const [{ currentView, expEndDate, expStartDate }, updateQueryParam] =
        useExperimentsQueryParams();

    return (
        <SpaceportCommonHeader
            title={(currentView !== 'overview' && currentView) || 'Experiments'}
            titleClassName="capitalize"
            description={
                currentView === 'overview'
                    ? 'Click on an experiment to view the results.'
                    : (currentView === 'launch' && 'Create a new A/B or DMS experiment below.') ||
                      `Track and analyze the experiment results below.`
            }
            actionsElement={<ActionsElement />}
            breadcrumbsCom={
                currentView !== 'overview' && (
                    <TBDBreadcrumb
                        crumbs={['experiments', currentView]}
                        onClick={e => updateQueryParam({ value: 'overview', key: 'currentView' })}
                    />
                )
            }
        />
    );
};
